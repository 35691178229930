import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Container, Col, Card } from 'react-bootstrap/'
import retmesPMS from "../../images/retmes_pms_logo.svg"
import TopHeader from "../../components/top-header";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const EnerjiYonetim = ({ intl }) => {
  const title = intl.formatMessage({ id: "page_pms_title" })
  const desc = intl.formatMessage({ id: "retmes-mms-box-desc" })

  return <Layout >
    <TopHeader />
    <SEO
      title={title}
      description={desc}
    />
    <Container>
      <h1 className='page-title'>{title}</h1>
      <Row className='content-page-row'>
        <Col className='content-page-col' sm={8}>
          <p className='content-page-p'>
            <FormattedMessage id="page_pms_desc" />
          </p>
        </Col>
        <Col className='content-page-col' sm={4} >
          <img className='content-page-image' src={retmesPMS} alt="retmes enerji yönetim sistemi" />
        </Col>
      </Row>
    </Container>
    <svg viewBox="0 0 1440 120" id="wave">
      <path fill="white" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
    </svg>
    <div className='landing-page-content-divider'>
      <Container style={{ backgroundColor: 'white' }}>
        <h2 className='page-title'><FormattedMessage id="package_includes" /></h2>
        <Row className='package-included-card-row'>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_1" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_1_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_2" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_2_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_3" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_3_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_5" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_5_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_6" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_6_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_7" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_7_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_20" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_20_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_19" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_19_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_14" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_14_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_13" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_13_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_21" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_21_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_11" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_11_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_25" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_25_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_23" /></Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_23_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_24" /> ({intl.formatMessage({ id: "optional" })})</Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_24_tip" />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='package-included-card'>
            <Card.Body>
              <Card.Title as='h3'><FormattedMessage id="solutions_table_module_4" /> ({intl.formatMessage({ id: "optional" })})</Card.Title>
              <Card.Text>
                <FormattedMessage id="solutions_table_module_4_tip" />
              </Card.Text>
            </Card.Body>
          </Card>


        </Row>
        <Row className='home-row-2'>
          <Col sm style={{ alignSelf: 'center', marginTop: '1em', marginBottom: '0px' }} className='head-col-1'  >
          <strong className='support-title'><FormattedMessage id="modules_ad_2_title_h1" /></strong> <br />
            <p style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center' }}><FormattedMessage id="solutions_ad_desc_p" /></p> <br />
            <Link to='/iletisim'> <div className='retmes-detail-button'><FormattedMessage id="get_support" /> </div></Link>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
}

export default injectIntl(EnerjiYonetim)